.main-page-history {
    min-height: 100vh;
    .player-avatar {
        border-radius: 50%;
        overflow: hidden;
    }

    .main-table {
        height: 100%;
        top: 0;
        min-height: 100vh;
    }
}

.scoring-history-container {
    width: var(--scoring-history-container-width, 350px);
    flex-direction: var(--scoring-history-container-flex-direction, column);
    .mobile & {
        @media screen and (orientation: landscape) {
            --scoring-history-container-width: 305px;
        }
        @media screen and (orientation: portrait) {
            --scoring-history-container-width: 100%;
        }
    }           
}

.mobile {
    &.main-page-history {
        .result-you,
        .result-me {
            @media screen and (orientation: landscape) {
                bottom: auto;
                top: 0;
            }
        }

        .scoring-history {
            @media screen and (orientation: portrait) {
                left: 15px;
            }
        }
    }
}

.table-history-input > div {
    height: 100%;
}

.table-history-info {
    // height: 200px;
    margin-bottom: 15px;
    .mobile & {
        height: auto;
        margin-bottom: 10px;
        @media screen and (orientation: portrait) {
            max-width: 350px;
        }
    }
}