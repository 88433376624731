$deck-w-scopa: 65px;
$deck-h-scopa: 111px;
$deck-w-scopa-mb: 33px;
$deck-h-scopa-mb: 56px;

.Scopa,
.Briscola,
.AssoPigliaTutto  {
    .board-wrapper {
        margin-top: var(--board-wrapper-margin-top, 0);
        transform-origin: top;
    } 

    .deck {
        width: $deck-w-scopa;
        height: $deck-h-scopa;
        border-radius: 4px;

        .mobile & {
            width: $deck-w-scopa-mb;
            height: $deck-h-scopa-mb;
            border-radius: 2px;

            .number-deck {
                font-size: 18px;
                bottom: 10%;
            }

            &:not(.hidden) {
                &::before {
                    border-radius: 2px;
                    top: 1px;
                    left: 1px;
                    right: 1px;
                    bottom: 1px;
                }

                &::after {
                    font-size: 6.5px;
                }
            }
        }
    }


    .card {
        width: 87px;
        height: 144px;

        &.my-card {
            width: 96px;
            height: 155px;
        }

        &.oppo-card {
            width: 69px;
            height: 115px;
        }

        .mobile & {
            width: 54px;
            height: 88px;

            &.my-card {
                width: 61px;
                height: 101px;

                @media (orientation: landscape) {
                    width: 52px;
                    height: 83px;
                }
            }

            &.oppo-card {
                width: 22px;
                height: 38.5px;
            }
        }

        .back,
        .card-deck {
            border-radius: 4px;
        }
    }

    .pile .card {
        width: 44px;
        height: 72px;

        .mobile & {
            width: 20px;
            height: 32px;
        }
    }

    .type-horizontal,
    .type-vertical {
        .player {
            &-avatar {
                width: 80px;
                height: 80px;

                .mobile & {
                    width: 46px;
                    height: 46px;
                }
            }

            &-content {
                margin-left: -15px !important;
                margin-right: -15px !important;

                .mobile & {
                    margin-left: -10px;
                    margin-right: -10px;
                }
            }
        }
    }

    .card,
    .deck {
        img {
            border-radius: 8px;

            .mobile & {
                border-radius: 4px;
            }
        }

        // &.my-card img {
        //     border-radius: 4px;
        // }

        .back,
        .card-deck {
            .mobile & {
                border-width: 1px;
                border-radius: 2px;
                background-size: 55%;
                image-rendering: pixelated;
                image-rendering: -moz-crisp-edges;
                image-rendering: -o-crisp-edges;
                image-rendering: -webkit-optimize-contrast;
            }
        }
    }

    .mobile & {
        --player-content-width: 95px; 
        --board-wrapper-margin-top: 0//28px;
        --table-width: auto;
        --table-height: auto;
    }
}