$deck-w-peppa: 65px;
$deck-h-peppa: 111px;
$deck-w-peppa-mb: 33px;
$deck-h-peppa-mb: 56px;

.main-table-Peppa {
    top: 80px;
}

.Peppa {
    &.table-Peppa {
        height: calc(100% - 20px);
        width: calc(100vw - 130px);

        .mobile & {
            width: auto;
            height: auto;
            position: fixed;
            top: 5px;
            left: 5px;
            bottom: 5px;
            right: 5px;
        }
    }

    .card-deck {
        position: relative;
        z-index: 2;
    }

    .deck {
        width: $deck-w-peppa;
        height: $deck-h-peppa;
        border-radius: 4px;

        .mobile & {
            width: $deck-w-peppa-mb;
            height: $deck-h-peppa-mb;
            border-radius: 2px;

            .number-deck {
                font-size: 18px;
                bottom: 10%;
            }

            &:not(.hidden) {
                &::before {
                    border-radius: 2px;
                    top: 1px;
                    left: 1px;
                    right: 1px;
                    bottom: 1px;
                }

                &::after {
                    font-size: 6.5px;
                }
            }
        }
    }


    .card {
        width: 80px;
        height: 132px;

        &.my-card {
            width: 80px;
            height: 132px;
        }

        &.oppo-card {
            width: 56px;
            height: 95px;
        }

        .mobile & {
            width: 54px;
            height: 88px;

            &.my-card {
                width: 52px;
                height: 83px;

                @media (orientation: landscape) {
                    width: 52px;
                    height: 83px;
                }
            }

            &.oppo-card {
                width: 20px;
                height: 37px;
            }
        }

        .back,
        .card-deck {
            border-radius: 4px;
        }
    }

    .pile .card {
        width: 44px;
        height: 72px;

        .mobile & {
            width: 20px;
            height: 32px;
        }
    }

    .type-horizontal,
    .type-vertical {
        .player {
            &-avatar {
                width: 70px;
                height: 70px;

                .mobile & {
                    width: 46px;
                    height: 46px;
                }
            }

            &-name {
                height: 40px;

                .mobile & {
                    height: 24px;
                    width: 95px;
                }
            }

            &-content {
                margin-left: -20px !important;
                margin-right: -15px !important;

                .mobile & {
                    margin-left: -15px !important;
                    margin-right: -10px;
                }
            }
        }
    }

    .card,
    .deck {
        img {
            border-radius: 8px;

            .mobile & {
                border-radius: 4px;
            }
        }

        // &.my-card img {
        //     border-radius: 4px;
        // }

        .back,
        .card-deck {
            .mobile & {
                border-width: 1px;
                border-radius: 4px;
                background-size: 55%;
                image-rendering: pixelated;
                image-rendering: -moz-crisp-edges;
                image-rendering: -o-crisp-edges;
                image-rendering: -webkit-optimize-contrast;
            }
        }
    }
}