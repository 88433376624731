$deck-w-briscola: 65px;
$deck-h-briscola: 111px;
$deck-w-briscola-mb: 33px;
$deck-h-briscola-mb: 56px;

.Briscola {
    .mobile & {
        --player-content-width: 95px; 
    }

    .card-deck {
        position: relative;
        z-index: 2;
    }

    .card-trump {
        width: var(--card-trump-width, $deck-w-briscola) !important;
        height: var(--card-trump-height, $deck-h-briscola) !important;
        border-radius: var(--card-trump-border-radius, 4px);
        left: var(--card-trump-left, 35px);
        rotate: 80deg;
    }

    .deck-vertical {
        top: 48px;

        .card-trump {
            left: -28px;
            rotate: -80deg;
        }
    }

    .pilePointParent {
        color: #fff;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 10000;
        top: var(--pilePointParent-top, 14px);
        left: var(--pilePointParent-left, -14px);
        height: var(--pilePointParent-width, 44px);
        width: var(--pilePointParent-height, 72px);
    }

    .pilePointTotal {
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        z-index: 10000;
        display: var(--pilePointTotal-display);
    }
    
    .pilePoint {
        color: #fff;
        font-size: var(--pilePoint-font-size, 20px);
        font-weight: bold;
        z-index: 10000;
        line-height: 1.0;
    }
}

.mobile {
    --card-trump-animate-width: 33px;
    --card-trump-animate-height: 56px;
    --card-trump-animate-border-radius: 2px;

    --card-trump-width: 33px;
    --card-trump-height: 56px;
    --card-trump-left: 20px;
    --card-trump-border-radius: 2px;

    --pilePoint-font-size: 14px;
    --pilePoint-position: absolute;
    --pilePoint-top: 34px;
    --pilePoint-left: -4px;

    --pilePointTotal-display: none;

    --pilePointParent-width: 20px;
    --pilePointParent-height: 32px;
    --pilePointParent-top: 6px;
    --pilePointParent-left: -6px;
}