.Scala40 { 
    &.table-Scala40 {
      --table-height: calc(100% - 20px);
      --table-width: calc(100vw - 130px);

        .mobile & {
            --table-width: calc(100% - 10px);
            --table-round-align-items: center;
            display: flex;
            @media screen and (orientation: portrait) {
                --table-height: calc(100% - 60px);
                top: 10px !important;
            }
            @media screen and (orientation: landscape) {
                --table-height: calc(100% - 60px);
                top: 15px !important;
            }
        }
    }

    .my-card,
    .oppo-card {
        .desktop & {
            box-shadow: 0 0 5px black;
        }
    }

    .mobile & {

        .result-you,
        .result-me {
            font-size: 8px;
        }

        @media screen and (orientation: landscape) {
            .result-you {
                left: 5px;
            }

            .result-me {
                right: 5px;
            }

            .result-you,
            .result-me {
                bottom: 2px;
                width: 310px;
            }
        }
    }

    .list-meld {
        display: block;
        position: absolute;
        z-index: -1;
        width: var(--list-meld-width, 570px);
        height: var(--list-meld-height, 435px);
        top: var(--list-meld-top, 122px);
        left: var(--list-meld-left, auto);
        bottom: var(--list-meld-bottom, auto);
        right: var(--list-meld-right, auto);
        border-radius: 8px !important;
        background-color: var(--list-meld-background-color, #095958);
      
        &.active {
          --list-meld-background-color: #076260;
          box-shadow: 0px 0px 20px 0px #369694 inset;
        }
      
        .mobile & {
          @media screen and (orientation: landscape) {
            --list-meld-width: 261px;
            --list-meld-bottom: 90px;
            --list-meld-left: 75px;
            --list-meld-top: 100px;
            --list-meld-height: auto;
          }
      
          @media screen and (orientation: portrait) {
            --list-meld-height: 137px;
            --list-meld-width: 100%;
            --list-meld-left: 0;
          }
        }
      }
      
      .list-meld-us {
        --list-meld-left: 120px;
      
        .mobile & {
          @media screen and (orientation: landscape) {
            transform: translate(0, 0);
          }
      
          @media screen and (orientation: portrait) {
            --list-meld-height: auto;
            --list-meld-top: 393.5px;
            --list-meld-bottom: 146.5px;
            --list-meld-left: 0;
          }
        }
      }
      
      .list-meld-them {
        --list-meld-right: 120px;
      
        .mobile & {
          @media screen and (orientation: portrait) {
            --list-meld-top: 105px;
          }
      
          @media screen and (orientation: landscape) {
            --list-meld-left: auto;
            --list-meld-right: 75px;
            transform: translate(0, 0);
          }
        }
      }
      
      .bg-show-meld {
        background-color: #076260;
        border-radius: 8px;
        position: absolute;
        z-index: -1;
        top: var(--bg-show-meld-top, 122px);
        height: var(--bg-show-meld-height, 394px);
        left: var(--bg-show-meld-left, 120px);
        right: var(--bg-show-meld-right, 120px);
        display: var(--bg-show-meld-display, none);
        bottom: var(--bg-show-meld-bottom);
      
        .mobile & {
          --bg-show-meld-display: block;
          @media screen and (orientation: portrait) {
            --bg-show-meld-bottom: 128px;
            --bg-show-meld-top: 105px;
            --bg-show-meld-left: 0;
            --bg-show-meld-right: 0;
            --bg-show-meld-height: auto;
      
            .table-logo {
              margin-top: -20px;
            }
          }
      
          @media screen and (orientation: landscape) {
            --bg-show-meld-top: 100px;
            --bg-show-meld-height: 153px;
            --bg-show-meld-left: 74.5px;
            --bg-show-meld-right: 74.5px;
            --bg-show-meld-bottom: 90px;
            --bg-show-meld-height: auto;
          }
        }
      }
}

.scala40-deck {
    position: absolute;
    bottom: 0;
    left: 137px;
    display: flex;

    .mobile & {
        scale: 0.6976744186046512;

        @media screen and (orientation: landscape) {
            left: 68px;
            bottom: 7px;
        }

        @media screen and (orientation: portrait) {
            left: 123px;
            top: 293px;
            bottom: auto;
        }
    }
}

.player-sort-box {
    position: absolute;
    display: flex;
    z-index: 200;
}

.bg-game-Scala40 {
    .header-mobile {
        .mobile & {
            @media screen and (orientation: portrait) {
                margin-top: 15px;
            }
        }
    }
}

.Scala40,
.Burraco {
    .table-result-content {
        padding: 10px 5px;
        flex-direction: column;
        gap: 8px;
        display: flex;

        >div {
            width: 100%;
            line-height: 17px;
        }
    }
}