.main-table-Burraco {
    top: 80px;
    height: calc(100% - 80px);
}

.Burraco {
    
    .board-wrapper {
        top: 0px;
    }
    .burraco-meld-number {
        color: white;
        font-family: 'Inter', sans-serif;
        font-weight: 700;
        line-height: 24px;
        padding: 5px 10px;
        border-top-left-radius: 20px;
        right: 0;
        font-size: 16px;
        border-left: 2px solid #FFC857;
        border-top: 2px solid #FFC857;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    }
    
    .meld-not-burraco {
        background-color: #0E3636;
    }
    
    .meld-burraco-clean {
        background-color: #D86502;
    }
    .meld-burraco-semi {
        background-color: #c58609;
    }
    
    .meld-burraco-dirty {
        background-color: #830005;
    }
    
    .burraco-meld-bottom {
        border-radius: 999px;
        background: linear-gradient(180deg, #0A9898 0%, #0E3636 80.73%, #0E3636 96.87%);
        width: 102px;
        height: 8px;
        bottom: -4px;
        right: 0px;
        z-index: 1000;
        position: absolute;
    }
    
    .burraco-meld-bottom-not-burraco {
        background: linear-gradient(180deg, #0A9898 0%, #0E3636 80.73%, #0E3636 96.87%);
    
    }
    
    .burraco-meld-bottom-clean {
        background: linear-gradient(180deg, #F82 0%, #F25F07 39.06%, #3C1414 100%);
    }
    
    .burraco-meld-bottom-semi {
        background: linear-gradient(180deg, #c58609 0%, #fdc34e 100%);
    }
    
    .burraco-meld-bottom-dirty {
        background: linear-gradient(180deg, #E40000 0%, #461111 100%);
    }
    
    .burraco-point {
        font-family: Metropolis;
        background-clip: text;
        line-height: normal;
        color: #FCFC4E;
    }
    
    .burraco-point-card {
        @extend .burraco-point;
        text-shadow: -2px 4px 0px rgba(125, 8, 22, 0.49),
            2px 3px 0px #322200;
        font-size: 14px;
        top: -15px;
        left: 3px;
        margin-right: 5px;
        animation: showPoints 5s ease-in-out infinite;
        -webkit-text-stroke: rgba(125, 8, 22, 0.49);
        -webkit-text-stroke-width: 0.2px;
        will-change: transform;
    
        @media screen and (max-width: 600px) {
            font-size: 14px;
            top: -25px;
        }
    
    }
    
    .burraco-point-meld {
        @extend .burraco-point;
        text-shadow: -2px 4px 0px rgba(125, 8, 22, 0.49),
            2px 3px 0px #322200;
        font-size: 25px;
        top: -45px;
        margin-right: 5px;
        animation: showPoints 5s ease-in-out infinite;
        -webkit-text-stroke: rgba(125, 8, 22, 0.49);
        -webkit-text-stroke-width: 0.5px;
        will-change: transform;
    }

    &.table-Burraco {
        height: calc(100% - 20px);
        width: calc(100vw - 130px);

        .mobile & {
            margin-bottom: 0;
            width: calc(100% - 10px);
            height: calc(100% - 20px);

            @media screen and (orientation: portrait) {
                margin-top: -10px;
            }
        }
    }

    .my-card,
    .oppo-card {
        .desktop & {
            box-shadow: 0 0 5px black;
        }
    }

    .mobile & {

        .result-you,
        .result-me {
            font-size: 8px;
        }

        @media screen and (orientation: landscape) {
            .result-you {
                left: 5px;
            }

            .result-me {
                right: 5px;
            }

            .result-you,
            .result-me {
                bottom: 2px;
                width: 310px;
            }
        }
    }

    .list-meld {
        width: var(--list-meld-width, 575px);
        height: var(--list-meld-height, 394px);
        top:  var(--list-meld-top, 122px);

        .mobile & {
            @media screen and (orientation: landscape) {
                --list-meld-width: 261px;
                bottom: 65px;
                left: 75px;
                --list-meld-top: 110px;
                --list-meld-height: auto;
            }

            @media screen and (orientation: portrait) {
                --list-meld-height: 137px;
                --list-meld-width: 100%;
            }
        }
    }

    .list-meld-us {
        left: var(--list-meld-us-left, 120px);

        .mobile & {
            @media screen and (orientation: portrait) {
                top: 440px;
                bottom: 130px;
                --list-meld-us-left: 0;
            }
            @media screen and (orientation: landscape) {
                top: 90px;
                bottom: 95px;
                --list-meld-us-left: 0;
            }
           
        }
    }

    .list-meld-them {
        right: 120px;

        .mobile & {
            @media screen and (orientation: portrait) {
                top: 130px;
            }

            @media screen and (orientation: landscape) {
                top: 90px;
                bottom: 95px;
                --list-meld-us-left: 0;
                left: auto;
                right: 75px;
            }
        }
    }

    .bg-show-meld {
        background-color: #076260;
        border-radius: 8px;
        position: absolute;
        z-index: -1;
        display: block;
        top: 122px;
        height: 394px;
        left: 120px;
        right: 120px;

        .mobile & {

            @media screen and (orientation: portrait) {
                bottom: 128px;
                top: 105px;
                left: 0;
                right: 0;
                height: auto;
            }

            @media screen and (orientation: landscape) {
                top: 100px;
                width: 575px;
                height: 153px;
                left: 74.5px;
            }
        }
    }

    .player-bg {
        .desktop & {
            box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.40);
        }
    }
}

.burraco-deck {
    position: absolute;
    top: 523.5px;
    left: 397px;
    scale: 0.872093023255814;
    display: flex;

    .mobile & {
        scale: 0.5263;

        @media screen and (orientation: landscape) {
            left: 332px;
            transform: rotate(90deg);
            top: 70px;

            .number-deck {
                rotate: 180deg;
                writing-mode: vertical-rl;
                left: 22%;
                top: 0;
                width: 100%;
                height: 100%;

                .Scopa & {
                    left: 12px;
                }
            }

            .deck::after {
                rotate: 180deg;
                writing-mode: vertical-rl;
                left: -12px;
                top: 0;
                width: 100%;
                height: 100%;
                padding: 30px;
                font-size: 9px;
            }
        }

        @media screen and (orientation: portrait) {
            left: 63px;
            top: 283px;
            bottom: auto;
        }
    }
}

.bg-game-Burraco {
    .header-mobile {
        .mobile & {
            @media screen and (orientation: portrait) {
                margin-top: 15px;
            }
        }
    }
}

.burraco-pozzetto {
    position: absolute;
    top: 523.5px;
    left: 303px;
    scale: 0.872093023255814;

    .mobile & {
        scale: 0.48316762602;

        @media screen and (orientation: portrait) {
            top: auto;
            left: 20px;
            bottom: 10px;
        }

        @media screen and (orientation: landscape) {
            top: 15px;
            right: 100px;
            left: auto;
        }
    }
}

.burraco-bg-discard {
    position: absolute;
    top: 522px;
    left: 481px;
    width: 624px;
    padding: 7px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.08);
    height: 89px;

    .mobile & {
        width: 160px;
        padding: 3px;
        border-radius: 6px;
        background: #0F3D3C;
        height: 52px;

        @media screen and (orientation: portrait) {
            top: 300px;
            left: 115px;
            width: 150px;
        }

        @media screen and (orientation: landscape) {
            top: 170px;
            left: 309px;
            transform: rotate(90deg);
            width: 104px;
        }
    }
}