.checkbox-custom {
    .checkbox-input {
        &:checked {
            ~.checkbox-icon {
                background-color: #1FC3C1;
                border-color: #1FC3C1;

                img {
                    display: block;
                }
            }
        }
    }

    .checkbox-icon {
        background-color: #fff;
        border-color: #9EA8B4;

        img {
            display: none;
        }
    }
}

.raise-option {
    .ant-radio-button-wrapper {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        padding: 0;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 700;
        border: 0.5px solid #FFFFFF;
        transition: all 0.2s ease-in;

        .mobile & {
            width: 45px;
            height: 45px;
            font-size: 12px;
        }

        &:hover {
            background-image: linear-gradient(rgb(0 0 0/20%) 0 0);
        }

        &-checked {
            border: 2px solid #FEBF10 !important;
        }

        &::before {
            display: none;
        }

        &:first-child {
            color: #175EBE;
            background-color: #fff;
        }

        &:nth-child(2) {
            color: #fff;
            background-color: #175EBE;
        }

        &:nth-child(3) {
            color: #fff;
            background-color: #0B3C7D;
        }

        &:nth-child(4) {
            color: #fff;
            background-color: #09346F;
        }
    }
}

.table-button-list .ant-drawer-open {
    min-width: 100%;

    .ant-drawer-content-wrapper {
        width: 110px;
        height: 300px !important;

        .mobile & {
            width: 90px;
            margin-bottom: 5px !important;

            @media screen and (orientation: landscape) {
                height: 270px !important;
            }

            @media screen and (orientation: portrait) {
                height: 300px !important;
            }
        }
    }
}

.ant-slider-rail {
    background: linear-gradient(180deg, #09346E 1.31%, #297FF2 98.04%);
    box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.25);
    border-radius: 999px !important;
    width: 12px !important;
}

.ant-slider-handle {

    &::after,
    &::before {
        display: none;
    }
}

.table-button-list {
    .ant-drawer-mask {
        display: none;
    }

    .btn {
        min-width: 153px;
        padding: 0 10px;
    }

    .mobile & {
        .btn {
            min-width: 90px;
            width: 100%;
            max-width: 110px;
        }

        @media screen and (orientation: landscape) {
            padding-left: 35%;
        }

        @media screen and (orientation: portrait) {

            >.btn,
            >div,
            .btn {
                flex: 1;
                min-width: auto;

                >.btn {
                    width: 100%;
                }
            }
        }
    }
}

.itpoker-actions {
    .mobile & {
        @media screen and (orientation: landscape) {
            .btn:nth-child(3) {
                +.modal-open {
                    margin-right: 50px;
                }
            }
        }
    }

    .modal-open {
        flex: 1;
    }

    .btn:first-child {
        +.modal-open {
            @media screen and (orientation: portrait) {
                flex: 0;
                min-width: 110px;
            }
        }
    }
}