.ItalianPoker {
    --card-height: 144px;
    --card-width: 96px;
    --card-top: 0;
    --card-left: 0;
    &#table-round {
        align-items: center;
        .mobile & {
            @media screen and (orientation: portrait) {
                bottom: 50px;
            }
        }
    }
    .player-seat {
        &.Fold {
            opacity: 0.5;
        }

        &.Busted {

            img,
            .player-content,
            .player-avatar::after {
                opacity: 0.5;
            }
        }
    }

    .pot {
        position: absolute;
        top: 30px;
        left: 0;
        width: 76px;
        height: 76px;

        &-label {
            position: absolute;
            bottom: -5px;
            left: 0;
            right: 0;
            color: #fff;
            font-weight: bold;
            text-align: center;
            .mobile & {
                font-size: 10px;
            }
        }
        .mobile & {
            width: 58px;
            height: 58px;
            top: 0;
        }
    }
}
