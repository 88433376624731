.unexpected-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 60px;
    min-height: 100vh;
    padding: 30px 40px;
    max-width: 1050px;
    margin: 0 auto;
    text-align: center;
    @media screen and (min-width: 1600px) {
        gap: 80px;    
    }
    img {
        max-height: 400px;
    }
    p {
        font-weight: 500;
        line-height: 1.3;
        font-size: 28px;
        @media screen and (min-width: 768px) {
            font-size: 36px;
        }
    }
    .unexpected-btn {
        border-radius: 999px;
        font-size: 34px;
        padding: 0 35px;
        height: 88px;
        color: var(--Blue, #06F);
        border: 2px solid var(--Blue, #06F);
        background: #FFF;
        min-width: 300px;
        @media screen and (min-width: 1600px) {
            font-size: 36px;
        }
    }
}