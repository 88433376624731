$deck-w-tresette: 65px;
$deck-h-tresette: 111px;
$deck-w-tresette-mb: 33px;
$deck-h-tresette-mb: 56px;

.main-table-Tresette {
    top: 80px;
}

.Tresette {
    &.table-Tresette {
        height: calc(100% - 20px);
        width: calc(100vw - 130px);

        .mobile & {
            width: auto;
            height: auto;
            position: fixed;
            top: 5px;
            left: 5px;
            bottom: 5px;
            right: 5px;
        }
    }

    .mobile & {
        .board-wrapper {
            position: absolute;
            top: 0;
            transform-origin: top;
            width: 100%;
        }
    }

    .card-deck {
        position: relative;
        z-index: 2;
    }

    .deck {
        width: $deck-w-tresette;
        height: $deck-h-tresette;
        border-radius: 4px;

        .mobile & {
            width: $deck-w-tresette-mb;
            height: $deck-h-tresette-mb;
            border-radius: 2px;

            .number-deck {
                font-size: 18px;
                bottom: 10%;
            }

            &:not(.hidden) {
                &::before {
                    border-radius: 2px;
                    top: 1px;
                    left: 1px;
                    right: 1px;
                    bottom: 1px;
                }

                &::after {
                    font-size: 6.5px;
                }
            }
        }
    }

    .card {
        width: 80px;
        height: 132px;

        &.my-card {
            width: 80px;
            height: 132px;
        }

        &.oppo-card {
            width: 56px;
            height: 95px;
        }

        .mobile & {
            width: 52px;
            height: 83px;

            &.my-card {
                width: 52px;
                height: 83px;
            }
            @media (orientation: landscape) {
                &, &.my-card {
                    width: 52px;
                    height: 83px;
                }
            }

            &.oppo-card {
                width: 20px;
                height: 37px;
            }
        }

        .back,
        .card-deck {
            border-radius: 4px;
        }
    }

    .pile .card {
        width: 44px;
        height: 72px;

        .mobile & {
            width: 20px;
            height: 32px;
        }
    }

    .card,
    .deck {
        img {
            border-radius: 8px;

            .mobile & {
                border-radius: 4px;
            }
        }

        .back,
        .card-deck {
            .mobile & {
                border-width: 1px;
                border-radius: 4px;
                background-size: 55%;
                image-rendering: pixelated;
                image-rendering: -moz-crisp-edges;
                image-rendering: -o-crisp-edges;
                image-rendering: -webkit-optimize-contrast;
            }
        }
    }
}