.main-table {
    & * {
        user-select: none;
        -moz-user-select: none;
        -webkit-user-drag: none;
        -webkit-user-select: none;
        -ms-user-select: none;
    }

    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 99px;
    height: calc(100% - 100px);

    .mobile & {
        top: 48px;
        height: calc(100% - 48px);

        @media (orientation: landscape) {
            position: static;
        }
    }
}

.scopa-message,
.settebello-message {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 9;
    width: 200px;
    height: 200px;

    @media screen and (min-width: 768px) {
        width: 350px;
        height: 350px;
    }

    &::before,
    &::after {
        content: '';
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
    }

    &:after {
        background-image: url('../../../public/images/common/asset_scopa.png');
        width: 0;
        height: 0;
        z-index: 99;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        transition: width 0.75s ease, height 0.75s ease;
    }

    &::before {
        background-image: url('../../../public/images/common/asset_radialLight.png');
        width: 200px;
        height: 200px;
        animation: scopaRotate 4s infinite linear;
        left: 0;
        top: 0;

        @media screen and (min-width: 768px) {
            width: 350px;
            height: 350px;
        }
    }
}

.active {
    &:after {
        width: 150px;
        height: 150px;
        transition: width 0.5s ease, height 0.5s ease;

        @media screen and (min-width: 768px) {
            width: 300px;
            height: 300px;
        }
    }
}

.settebello-message {
    &:after {
        background-image: url('../../../public/images/common/asset_settebello.png');
    }
}

@keyframes scopaRotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes scopaMessage {
    from {
        width: 0;
        height: 0;
    }

    to {
        width: 150px;
        height: 150px;

        @media screen and (min-width: 768px) {
            width: 350px;
            height: 350px;
        }
    }
}

.message-table {
    animation: messageTable 0.25s alternate;
    top: 25%;

    @media all and (max-height: 670px) and (max-width: 576px) {
        top: 30%
    }

    @media all and (min-width: 768px) {
        top: 23%;
    }

    @media all and (min-width: 1280px) {
        top: 19%;
    }
}

@keyframes messageTable {
    0% {
        top: 50%;

        @media all and (min-width: 768px) {
            top: 30%;
        }
    }

    100% {
        @media all and (max-width: 767px) {
            top: 35%;
        }

        @media all and (max-height: 670px) and (max-width: 576px) {
            top: 30%
        }

        @media all and (min-width: 768px) {
            top: 25%;
        }

        @media all and (min-width: 1280px) {
            top: 15%;
        }
    }
}

@keyframes winImages {
    0% {
        width: 70px;
        height: 70px;

        @media all and (min-width: 1024px) {
            width: 90px;
            height: 90px;
        }
    }

    100% {
        width: 90px;
        height: 90px;

        @media all and (min-width: 1024px) {
            width: 110px;
            height: 110px;
        }
    }
}

.player-collapse {
    writing-mode: tb;
}

.table-ItalianPoker, 
.table-Telesina {
    height: calc(100% - 20px);
    width: calc(100vw - 130px);

    .mobile & {
        width: auto;
        height: auto;
        position: fixed;
        top: 5px;
        left: 5px;
        bottom: 5px;
        right: 5px;
    }
}

.table-Burraco {
    height: calc(100% - 20px);
    width: calc(100vw - 130px);

    .mobile & {
        width: calc(100vw - 30px);
        height: calc(100% - 10px);
    }
}


.table-SetteEMezzo {
    height: calc(100vh - 150px);
    width: calc(100vw - 30px);
}

.table-Peppa {
    height: calc(100vh - 150px);
    width: calc(100vw - 30px);

    @media screen and (min-width: 576px) {
        height: calc(100% - 10px);
        width: calc(100vw - 50px);
    }

    @media screen and (min-width: 1024px) {
        margin-top: -20px;
    }
}

#table-round{
    height: var(--table-height, calc(100% - 20px));
    width: var(--table-width, calc(100vw - 130px));

    .mobile & {
        position: fixed;
        top: 5px;
        left: 5px;
        bottom: 5px;
        right: 5px;
        align-items: var(--table-round-align-items, flex-start);
    }
}

.Scopa,
.Scopone,
.Briscola,
.Trentuno {
    #table-round{
        height: var(--table-height, calc(100% - 20px));
        width: var(--table-width, calc(100vw - 130px));
    
        .mobile & {
            position: fixed;
            top: 5px;
            left: 5px;
            bottom: 60px;
            right: 5px;
            align-items: flex-start;
        }
    }
}

.mobile {
    --table-height: auto;
    --table-width: auto;
}

.button-action,
.button-chat {
    width: 54px;
    height: 54px;
    bottom: 16px;

    .mobile & {
        width: 40px;
        height: 40px;
        bottom: 42px;
    }
}


.button-action-ItalianPoker,
.button-action-Telesina,
.chat-ItalianPoker {
    .mobile & {
        @media screen and (orientation: landscape) {
            bottom: 17px;
        }

        @media screen and (orientation: portrait) {
            bottom: 70px;
        }
    }
}

.button-action-Burraco,
.button-action-Teleina,
.button-action-Scala40,
.button-action-Tresette,
.chat-Burraco,
.chat-Scala40,
.chat-Tresette {
    @media screen and (min-width: 768px) and (max-width: 1200px) {
        bottom: 60px !important;
    }
}

.table-in-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    border-radius: 999px;
    width: calc(100% - 260px);
    height: calc(100% - 180px);
    border: 3px solid rgba(255, 255, 255, 0.2);
    z-index: -1;

    .mobile & {
        @media screen and (orientation: landscape) {
            top: 14px;
            transform: translate(-50%, 0);
            width: calc(100% - 86px);
            height: calc(100% - 52px);
        }

        @media screen and (orientation: portrait) {
            top: 30px;
            transform: translate(-50%, 0);
            width: calc(100% - 42px);
            height: calc(100% - 66px);
        }
    }
}

.table-bottom {
    bottom: 12px;

    .mobile & {
        bottom: 5px;
    }
}

.board-card-game {
    width: 100%;
    height: 100%;
}

.dealer {
    .number-deck {
        bottom: 10px;
    }

    .deck:not(.hidden)::after {
        top: 10px;
    }
}

.main-table:not(.main-table-Scopa) {
    top: 0;
    height: 100%;

    &:not(.main-table-ItalianPoker) {
        .mobile & {
            @media screen and (orientation: landscape) {
                position: fixed;
            }
        }
    }
}