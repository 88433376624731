.Telesina {
  --card-height: 144px;
  --card-width: 96px;
  --card-top: 0;
  --card-left: 0;

  &#table-round {
    align-items: center;
  }

  .player-seat {
    &.Fold {
      opacity: 0.5;
    }

    &.Busted {

      img,
      .player-content,
      .player-avatar::after {
        opacity: 0.5;
      }
    }
  }

  .pot {
    position: absolute;
    top: 0;
    left: 0;
    width: 76px;
    height: 76px;

    &-label {
      position: absolute;
      bottom: -5px;
      left: 0;
      right: 0;
      color: #fff;
      font-weight: bold;
      text-align: center;

      .mobile & {
        font-size: 10px;
      }
    }

    .mobile & {
      width: 58px;
      height: 58px;
    }
  }

  .table-in-wrapper {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 45%;
    border-radius: 999px;
    width: calc(100% - 260px);
    height: calc(100% - 120px);
    border: 3px solid rgba(255, 255, 255, 0.2);
    z-index: -1;

    .mobile & {
      @media screen and (orientation: landscape) {
        top: 14px;
        transform: translate(-50%, 0);
        width: calc(100% - 86px);
        height: calc(100% - 52px);
      }

      @media screen and (orientation: portrait) {
        top: 30px;
        transform: translate(-50%, 0);
        width: calc(100% - 42px);
        height: calc(100% - 66px);
      }
    }
  }

  .hidden-card {
    border: 5px solid #f9be53;
  }

  .combination {
    width: 180px;
    height: 25px;
    font-size: 14px;
  }

  .player-info {
    scale: 0.8;
  }
}