$deck-w-bestia: 65px;
$deck-h-bestia: 111px;
$deck-w-bestia-mb: 33px;
$deck-h-bestia-mb: 56px;

.main-table-Bestia {
    top: 80px;
}

.Bestia {
    &#table-round {
        align-items: center;
    }
    &.table-Bestia {
        height: calc(100% - 20px);
        width: calc(100vw - 130px);

        .mobile & {
            width: auto;
            height: auto;
            position: fixed;
            top: 5px;
            left: 5px;
            bottom: 5px;
            right: 5px;
        }
    }

    .pot {
        position: absolute;
        top:  100px;
        left: 10px;
        width: 76px;
        height: 76px;
        z-index: 1000;

        &-label {
            position: absolute;
            bottom: -5px;
            left: 0;
            right: 0;
            z-index: 1000;
            color: #fff;
            font-weight: bold;
            text-align: center;
            .mobile & {
                font-size: 10px;
            }
        }
        .amount-text {
            text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
        }
        .mobile & {
            width: 58px;
            height: 58px;
        }
    }
    .deck {
        width: $deck-w-bestia;
        height: $deck-h-bestia;
        border-radius: 4px;

        .mobile & {
            width: $deck-w-bestia-mb;
            height: $deck-h-bestia-mb;
            border-radius: 2px;

            .number-deck {
                font-size: 18px;
                bottom: 10%;
            }

            &:not(.hidden) {
                &::before {
                    border-radius: 2px;
                    top: 1px;
                    left: 1px;
                    right: 1px;
                    bottom: 1px;
                }

                &::after {
                    font-size: 6.5px;
                }
            }
        }
    }

    .card-trump {
        width: $deck-w-bestia;
        height: $deck-h-bestia;
        border-radius: 4px;
        left: 73px;
        rotate: 70deg;
        top: 530px;
        .mobile & {
            width: $deck-w-bestia-mb;
            height: $deck-h-bestia-mb;
            border-radius: 2px;
            top: 540px;
            rotate: 250deg;
            left: 260px;
            scale: 0.7;
            @media (orientation: landscape) {
                top: 260px;
                rotate: 250deg;
                left: 580px;
            }
        }
    }

    .deck-card {
        width: $deck-w-bestia;
        height: $deck-h-bestia;
        border-radius: 4px;
        top: 530px;
        .mobile & {
            width: $deck-w-bestia-mb;
            height: $deck-h-bestia-mb;
            border-radius: 2px;
            top: 530px;
            scale: 0.7;
            left: 280px;
            @media (orientation: landscape) {
                top: 250px;
                left: 600px;
            }
        }
    }

    .point {
        font-size: 20px;
        font-weight: bold;
        display: flex;
        position: absolute;
        top: 500px;
        left: -10px;
        justify-content: center; 
        align-items: center; 
        background: #092020;
        color: #fff;
        border-radius: 100% !important;
        width: 40px;
        height: 40px;
        z-index: 100;
        .mobile & {
            scale: 0.5;
            border-radius: 2px;
            top: 525px;
            left: 305px;
            @media (orientation: landscape) {
                top: 245px;
                left: 625px;
            }
        }
    }


    .card {
        width: 87px;
        height: 144px;

        &.my-card {
            width: 96px;
            height: 155px;
        }

        &.oppo-card {
            width: 69px;
            height: 115px;
        }

        .mobile & {
            width: 54px;
            height: 88px;

            &.my-card {
                width: 61px;
                height: 101px;

                @media (orientation: landscape) {
                    width: 52px;
                    height: 83px;
                }
            }

            &.oppo-card {
                width: 22px;
                height: 38.5px;
            }
        }

        .back,
        .card-deck {
            border-radius: 4px;
        }
    }

    .pile .card {
        width: 44px;
        height: 72px;

        .mobile & {
            width: 20px;
            height: 32px;
        }
    }

    .type-horizontal,
    .type-vertical {
        .player-seat {
            transform: translate(-50%, -50%);
        }
        .player {
            &-avatar {
                width: 80px;
                height: 80px;

                .mobile & {
                    width: 46px;
                    height: 46px;
                }
            }

            &-content {
                margin-left: -15px !important;
                margin-right: -15px !important;
                padding-left: 20px;
                padding-right: 20px;

                .mobile & {
                    margin-left: -10px;
                    margin-right: -10px;
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }

    .card,
    .deck {
        img {
            border-radius: 8px;

            .mobile & {
                border-radius: 4px;
            }
        }

        // &.my-card img {
        //     border-radius: 4px;
        // }

        .back,
        .card-deck {
            .mobile & {
                border-width: 1px;
                border-radius: 2px;
                background-size: 55%;
                image-rendering: pixelated;
                image-rendering: -moz-crisp-edges;
                image-rendering: -o-crisp-edges;
                image-rendering: -webkit-optimize-contrast;
            }
        }
    }
}
