
.main-table-Scopone {
    top: 80px;
}

.Scopone {
    &.table-Scopone {
        height: calc(100% - 20px);
        width: calc(100vw - 130px);

        .mobile & {
            width: auto;
            height: auto;
            position: fixed;
            top: 5px;
            left: 5px;
            bottom: 5px;
            right: 5px;
        }
    }

    .mobile & {
        .board-wrapper {
            position: absolute;
            top: 0;
            transform-origin: top;
            width: 100%;
        }
    }

    .card {
        width: 70px;
        height: 120px;

        &.my-card {
            width: 80px;
            height: 132px;
        }

        &.oppo-card {
            width: 56px;
            height: 95px;
        }

        .mobile & {
            width: 52px;
            height: 83px;

            &.my-card {
                width: 52px;
                height: 83px;
            }
            @media (orientation: landscape) {
                &, &.my-card {
                    width: 52px;
                    height: 83px;
                }
            }

            &.oppo-card {
                width: 20px;
                height: 37px;
            }
        }
      
    }

    .pile .card {
        width: 44px;
        height: 72px;

        .mobile & {
            width: 20px;
            height: 32px;
        }
    }
    
}

#scopone-wrapper {
    >.overflow-hidden.absolute {
        @media screen and (min-width: 1600px){
            right: -100px;
            scale: 0.8;
            bottom: 40px;
        }
    }
}