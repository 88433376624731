@import "button.scss";
@import "checkbox.scss";
@import "table.scss";
@import "games";

.ant-drawer-mask {
  background-color: transparent !important;
}

.sidebar-item {
  &:last-child {
    border-bottom: none !important;
  }
}

.sidebar-link {
  &::before {
    background-color: #f7f7f9;
  }
}

.sidebar-close {
  &::before {
    background-color: #cd1b1b;
  }

  &:hover path {
    stroke: #fff;
  }
}

.scroll-bar,
.ant-drawer-body {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba($color: #828b92, $alpha: 0.12);
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
}

.ant-input:focus,
.ant-input:hover,
.ant-input-focused {
  border: 1px solid #175ebe !important;
  box-shadow: none !important;
  outline: 0 !important;
}

.ant-modal-content {
  border-radius: 12px !important;
  box-shadow: 0px 3px 66px rgba(11, 14, 17, 0.06);
  padding: 24px !important;

  .ant-modal-header {
    margin-bottom: 24px;
  }

  .ant-modal-title {
    font-size: 20px;
    color: #0b0e11;
    font-weight: 700;
  }
}

.ant-modal .ant-modal-close {
  width: 36px;
  height: 36px;

  &:hover {
    background-color: transparent;
  }
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 13px 16px;
  white-space: nowrap;
}

.table-room {
  .ant-table-tbody > tr {
    &:nth-child(odd) {
      background-color: #f7f7f9;

      td {
        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }

    > td {
      border: none !important;
    }
  }

  .ant-table-thead > tr > th {
    background-color: transparent !important;
    border: none !important;
    padding: 8px 16px;
    color: #9ea8b4;
    font-weight: 400;

    &::before {
      display: none;
    }
  }
}

.card.active {
  top: -35px !important;
}

.card.active.border {
  border: blue;
  border-style: solid;
}

.hhand {
  display: inline-block;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.Toastify__toast-container {
  padding: 0;
}

.toast-custom {
  border: 1px solid #fff8f8;
  border-radius: 8px !important;
  background: #0d282a !important;
  padding: 12px 20px !important;
  box-shadow: 0px 4px 4px rgba(7, 7, 7, 0.25) !important;
  margin: 0;

  .Toastify__progress-bar {
    opacity: 0 !important;
  }

  .Toastify__toast-body {
    @extend .font-raleway;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    padding: 0;
  }

  .Toastify__close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
  }

  .Toastify__close-button--light {
    color: #fff;
    opacity: 1;
  }
}

.font-raleway {
  font-family: "Raleway", sans-serif !important;
}

.toast-center {
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 100%;
  max-width: 400px;
  margin-top: 24px;

  @media screen and (max-width: 1024px) {
    margin-top: 0;
  }
}

.Toastify__toast-container--top-left {
  @media screen and (max-width: 480px) {
    transform: none;
  }
}

@media screen and (max-width: 500px) and (max-height: 900px) {
  .toast-portrait {
    position: fixed;
    width: 100%;
    max-width: 400px;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin-top: 350px;
  }
}

.MuiModal-backdrop {
  position: absolute !important;
}

.color-primary {
  color: #0b787c;
}

.svg-footer-Premio {
  * {
    height: 20px !important;
    width: 20px !important;
  }
}

.svg-footer-Triplete {
  * {
    height: 20px !important;
    width: 17px !important;
  }
}

.table-result {
  .ant-tabs .ant-tabs-tab {
    padding: 0 !important;
    flex: 1;
    opacity: 0.3;

    &.ant-tabs-tab-active {
      opacity: 1;
    }
  }

  .ant-tabs-nav::before {
    display: none !important;
  }

  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-tab-btn,
  .ant-tabs-nav-list {
    width: 100%;
  }

  .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
    margin: 0;
  }
}

.imgages-card {
  &:not(.active) {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
}

.modal-custom {
  .ant-modal-content {
    padding: 0 !important;
  }

  .ant-modal-close {
    top: 0;
    right: 0;
    color: #fff;
  }

  .ant-modal-content {
    overflow: hidden;
  }

  .btn {
    height: 40px;
    text-transform: none;
  }
}

.introjs-tooltip {
  * {
    @extend .font-raleway;
  }

  .introjs-tooltiptext {
    padding: 20px;
    @extend .font-raleway;
    font-weight: 500;
    font-size: 16px;
  }

  .introjs-tooltip-header,
  .introjs-tooltip-title {
    min-height: 1px;
  }

  .introjs-skipbutton {
    text-indent: 9999px;
    background-image: url(./../../../public/images/common/icon-close-red.svg);
    background-repeat: no-repeat;
    top: -20px;
    right: -20px;
  }

  .introjs-tooltipbuttons {
    padding: 10px 15px;
  }

  .introjs-button {
    @extend .btn;
    font-weight: normal;
    text-transform: none;
    width: 80px;
    padding: 0 10px;
    height: 35px;
    font-size: 15px;
    border-radius: 8px;

    &::before {
      display: none;
    }
  }

  .introjs-disabled {
    opacity: 0.5;
  }

  .introjs-nextbutton {
    @extend .btn-success;
    border: 1px solid #175ebe !important;
  }

  .introjs-prevbutton {
    @extend .btn-secondary;
  }

  .introjs-tooltipbuttons {
    border-top: none;
  }

  .introjs-bullets ul li a {
    background: #175ebe;
    opacity: 0.3;
    border-radius: 3px;
    width: 15px;
    height: 2px;
    margin: 0 3px;

    &.active,
    &:hover {
      opacity: 1;
      background: #175ebe;
    }
  }
}

.table-line {
  padding: 10px 20px;
  @extend .font-raleway;
  font-weight: 500;
}

.table-line-user {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 0;
}

.table-line-action {
  flex: 1;
  padding-right: 0;
}

.table-detail-bg .table-item {
  &:nth-child(2n) {
    background-color: #f7f7f9;
  }
}

.rule-game {
  p {
    margin-bottom: 15px;
  }

  .hearts,
  .diamonds,
  .clubs,
  .spades {
    font-size: 20px;
    line-height: 0.8rem;
  }

  .hearts,
  .diamonds {
    color: #f00;
  }

  .p-img {
    display: flex;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 15px;
    margin-left: 20px;

    li {
      list-style: initial;
    }

    ul {
      margin-top: 10px;

      li {
        list-style: circle;
      }
    }
  }

  table {
    width: 100%;
    margin-bottom: 20px;

    th {
      background-color: #cbcbcb;
      font-size: 14px;
    }

    td,
    th {
      // padding: 0.75rem;
      padding: 10px 20px;
      vertical-align: middle;
      border: 1px solid #dee2e6;
    }

    tr:nth-of-type(odd) {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  a {
    color: #0e345e;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  h5 {
    font-size: 16px;
  }
}

.rule-tabs {
  font-size: 16px;
  line-height: 1.5;
  @extend .font-raleway;
  width: 100%;
  position: relative;

  @media screen and (max-width: 576px) {
    max-height: inherit !important;
  }

  .ant-tabs-nav {
    background-color: #f1f2fb;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #d9d9d9;

    .ant-tabs-tab {
      width: 100%;
      padding: 0;

      @media screen and (min-width: 576px) {
        width: 270px;
      }
    }

    .ant-tabs-tab-btn {
      padding: 0 15px 0 24px;
      height: 45px;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .ant-tabs-tab,
    .ant-tabs-tab-btn {
      font-weight: 700;
      font-size: 14px;

      + .ant-tabs-tab {
        margin-top: 5px;
      }
    }

    .ant-tabs-tab-active {
      background-color: transparent;

      > .ant-tabs-tab-btn {
        color: #fff;
        background-color: #27347f;
      }
    }

    .ant-tabs-tab:hover:not(.ant-tabs-tab-active) {
      background-color: transparent;

      > .ant-tabs-tab-btn {
        color: #fff;
        background-color: #3f51b5;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  .ant-tabs-content-holder {
    border: none;
    flex: 1;
  }

  .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
    padding-left: 15px;

    @media screen and (min-width: 576px) {
      padding-left: 40px;
    }
  }

  .ant-tabs-nav {
    @media screen and (max-width: 576px) {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      bottom: 0;
      overflow-y: auto;
      z-index: 9999;
      background-color: #fff;
      padding: 50px 10px 10px 10px;

      &.active {
        display: block;
      }
    }
  }

  .rule-game {
    @media screen and (max-width: 576px) {
      max-height: inherit !important;
    }
  }
}

.ant-tabs-nav-wrap,
.ant-tabs-nav-list {
  .mobile & {
    height: 100%;
  }
}

.sub-tabs-list {
  position: absolute;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: #f1f2fb;
  transition: right 0.3s ease-in-out;

  &.show {
    right: 0;
    z-index: 99;
  }
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #0e345e;
}

.img-emoji {
  width: 50px;
  position: absolute;
}

.Scopa .table-result-head {
  padding: 0 7px;
}

.modal-full {
  .MuiDialog-container {
    align-items: baseline;
  }

  .MuiDialog-paper {
    width: 100%;
    max-width: 1000px;
  }
}

.modal-confirm {
  .MuiDialog-paper {
    width: 100%;
  }

  .btn {
    text-transform: none;
  }
}

.form-group {
  + .form-group {
    margin-top: 15px;
  }
}

.ant-checkbox-wrapper {
  font-size: 15px;
}

.options-btn-group * {
  text-transform: none;
}

.ant-select-dropdown {
  z-index: 9999;
}

.options-item {
  @media screen and (min-width: 1024px) {
    flex: 1;
  }
}

.button-action-icon {
  svg {
    min-width: 24px;
  }
}

%bg-game {
  background-image: url("./../../../public/images/common/bg-mobile.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 576px) {
    background-image: url("./../../../public/images/common/bg-new.svg");
  }
}

.bg-game-ItalianPoker,
.bg-game-Scala40,
.bg-game-Burraco,
.bg-game-Bestia {
  background-color: #114443;
}

.bg-game-Scopa,
.bg-game-Briscola,
.bg-game-Tresette,
.bg-game-Telesina,
.bg-game-Bestia,
.bg-game-Solitario,
.bg-game-Peppa {
  @extend %bg-game;
}

.Confirm-btn-group {
  flex-wrap: wrap;
}

.format-s {
  font-size: 22px;

  @media screen and (min-width: 1024px) {
    font-size: 26px;
  }
}

.bg-game-Scopa {
  .header-mobile {
    @media screen and (orientation: portrait) {
      margin-top: 15px;
    }
  }
}

.chat-desktop .MuiPaper-rounded {
  @media screen and (min-width: 1024px) {
    top: auto !important;
  }
}

.ant-tabs {
  display: flex;
}

.ant-tabs-nav-list {
  flex-direction: column;
}

.ant-tabs-nav {
  width: auto;
}

.ant-tabs-nav-operations {
  display: none;
}

.sub-tab-list {
  padding: 0;
  margin-top: 8px;
}

.sub-tab-list-item {
  margin: 0;
  padding: 0;
  width: 100%;
  border: none;
  text-align: left;
  transition: none;
}

.sub-tab-list-item a {
  display: block;
  padding: 8px 20px;
  border-bottom: 1px solid #f0f0f0;
  color: #333;
}

.sub-tab-list-item a:hover {
  background-color: #f0f0f0;
}

.search-form {
  input {
    height: 100%;
    padding: 0 14px;
    background: transparent !important;
  }
}

.rule-details-italianPoker {
  position: absolute;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  gap: 7px;
  flex-direction: column;
  font-family: "Raleway";
  font-weight: 500;
  justify-content: center;
  line-height: 16px;
  min-width: 144px;
  padding: 10px;
  font-size: 14px;
  left: 0;
  bottom: 0;

  .mobile & {
    @media screen and (orientation: portrait) {
      bottom: auto;
      left: auto;
      top: 0;
      right: 0;
      display: block;
      text-align: right;
      font-size: 10px;
      line-height: 12px;
      min-width: auto;
      background-color: transparent;
      padding: 0;
    }

    @media screen and (orientation: landscape) {
      right: 0;
      bottom: 84px;
      left: auto;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 10px;
      gap: 5px;
      min-width: 115px;
    }
  }
}

.rule-details-Bestia {
  position: absolute;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: flex;
  gap: 7px;
  flex-direction: column;
  font-family: "Raleway";
  font-weight: 500;
  justify-content: center;
  line-height: 16px;
  min-width: 144px;
  padding: 10px;
  font-size: 14px;
  left: 0;
  bottom: 0;

  .mobile & {
    @media screen and (orientation: portrait) {
      bottom: auto;
      left: auto;
      top: 0;
      right: 0;
      display: block;
      text-align: right;
      font-size: 10px;
      line-height: 12px;
      min-width: auto;
      background-color: transparent;
      padding: 0;
    }

    @media screen and (orientation: landscape) {
      right: 0;
      bottom: 84px;
      left: auto;
      font-size: 12px;
      line-height: 14px;
      padding: 7px 10px;
      gap: 5px;
      min-width: 115px;
    }
  }
}

.regulation-top {
  border-radius: 8px;
  overflow: hidden;

  .regulation-item {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Raleway";
    font-weight: 600;
    font-size: 15px;
  }
}

.Scopa .score-match-box {
  .mobile & {
    @media screen and (orientation: landscape) {
      margin-top: -50px;
    }
  }
}

.score-match-box {
  z-index: 9999;

  .mobile & {
    @media screen and (orientation: landscape) {
      zoom: 0.68;
    }
  }
}

.card-counter {
  color: #fff;
  background-color: #092020;
  border-radius: 99px;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  z-index: 999999;
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  &.mobile {
    width: 20px;
    height: 20px;
    font-size: 8px;
    line-height: 9.6px;
  }
}

.deck-pile-hightlight {
  --box-shadow-color: transparent;
  position: absolute;
  border-color: transparent;
  animation: blinking 2s infinite;
  box-shadow: 0 0 0 4px var(--box-shadow-color);
  border-radius: 8px;
  bottom: -4px;
  left: 130px;
  width: 140px;
  height: 95px;

  .mobile & {
    @media screen and (orientation: portrait) {
      top: 302px;
      left: 127px;
      width: 98px;
      height: 68px;
    }

    @media screen and (orientation: landscape) {
      top: 255px;
      left: 73px;
      width: 98px;
      height: 68px;
    }
  }
}

.select-card-input:focus-visible {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.select-card-box {
  .desktop & {
    z-index: 9999;
  }
  option {
    min-height: 30px;
    background-color: #fff !important;
    color: #000 !important;
  }
  .mobile & {
    @media screen and (orientation: landscape) {
      left: 20px;
      z-index: 9999;
      transform: scale(0.7);
      transform-origin: top left;
    }
    @media screen and (orientation: portrait) {
      label {
        color: #000;
      }
      select {
        border: 1px !important;
        border-style: solid !important;
        border-color: #000 !important;
        border-radius: 10px;
      }
      // top: auto;
      // bottom: 70px;
      // left: 20px;
    }
  }
}

.tournament-info-text {
  font-weight: 700;
  padding: 10px 0;
  cursor: pointer;
  .mobile & {
    span {
      display: none;
    }
  }
}

.tournament-info-list {
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #fff;
  background-color: #fff;
  color: #000;
  width: 250px;
  border-radius: 4px;
  padding: 10px;
  font-weight: 500;

  li {
    + li {
      margin-top: 5px;
      @media screen and (orientation: portrait) {
        margin-top: 4px;
      }
    }
  }
  strong {
    margin-left: 5px;
  }

  @media screen and (max-width: 900px) {    
    height: 200px;
    overflow: scroll;
  } 
}

.tournament-info {
  z-index: 9999;
  top: var(--tournament-info-top, 0px);
  .mobile & {
    font-size: 13px;
    .tournament-info-txt {
      display: none;
    }
  }
}

.mobile {
  --tournament-info-top: 18px;
}
