.Trentuno {
    --card-width: 95px;
    --card-height: 153px;
    --table-bottom: 0;
    --main-table-height: calc(100% - 90px);
    --deck-after-font-size: 14px;
    --deck-pile-hightlight-top: 244px;
    --deck-pile-hightlight-left: 516px;
    --deck-pile-hightlight-width: 200px;
    --deck-pile-hightlight-height: 153px;
    --card-left: 0;
    --card-top: 0;
    &.board-wrapper {
        .mobile & {
            transform-origin: top center;
        }
    }
    .deck {
        width: 95px;
        height: 153px;
        .mobile & {
            width: 55.5px;
            height: 89px;
        }
    }

    .mobile & {
        --card-width: 55.5px;
        --card-height: 89px;
        --deck-pile-hightlight-width: 55.5px;
        --deck-pile-hightlight-height: 89px;

        .btn {
            height: 30px;
        }

        @media screen and (orientation: portrait) {
            --table-bottom: 135px;
            --info-table-box-top: -30px;
            --info-table-box-right: 0;
            --deck-pile-hightlight-top: 270px;
            --deck-pile-hightlight-left: 142px;
        }

        @media screen and (orientation: landscape) {
            --deck-pile-hightlight-top: 118px;
            --deck-pile-hightlight-left: 289px;

            .table-button-list {
                margin-right: -46%;
            }
            .trentuno-table {
                transform: none;
            }
        }
    }

    .overlay {
        background-color: rgba(3, 3, 3, 0.50);;
    }
}

.trentuno-scoring-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.trentuno-scoring-text {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
}

.trentuno-scoring {
    z-index: 9999;
}

.trentuno-deck {
    position: absolute;
    top: var(--trentuno-deck-top, 244px);
    left: var(--trentuno-deck-left, 617px);
   
    .number-deck {
        top: var(--number-deck-top, 30px);
        left: 0;
        right: 0;
        bottom: 0;
        font-size: var(--trentuno-number-deck-font-size, 2.8rem);
    }
}

.player-point {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 50%;
    border: 1px solid #1A4C2F;
    background: #008C3B;
    color: #fff;
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    z-index: 200;
}

.trentuno-table {
    background-color: var(--trentuno-table-background-color, rgba(10,32,32,0.2));
    left: var(--trentuno-table-left, 0);
    right: var(--trentuno-table-right);
    bottom: var(--trentuno-table-bottom, 0);
    top: var(--trentuno-table-top);
    width: var(--trentuno-table-width, 190px) !important;
    position: var(--trentuno-table-position, absolute) !important;
    border-radius: 16px;
    overflow: hidden;
    background-color: #103939;
}

.mobile {
    --trentuno-table-top: 0;
    --trentuno-table-bottom: auto;
    --trentuno-table-left: auto;
    --trentuno-table-right: 50px;
    --trentuno-number-deck-font-size: 28px;
    --number-deck-top: 20px;
    
    .Trentuno {
        --deck-after-font-size: 9px;
    }

    .trentuno-table {
        backdrop-filter: blur(20px);
    }

    @media screen and (orientation: landscape) {
        --trentuno-deck-left: 348px;
        --trentuno-deck-top: 118px;
    }

    @media screen and (orientation: portrait) {
        --trentuno-deck-left: 142px;
        --trentuno-deck-top: 365px;
    }
    .trentuno-scoring {
        position: absolute;
        top: 0;
        right: 25px;
    }
}

.point-current-user {
    position: absolute;
}