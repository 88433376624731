$deck-w-solitario: 65px;
$deck-h-solitario: 111px;
$deck-w-solitario-mb: 33px;
$deck-h-solitario-mb: 56px;

.Solitario {
    --player-content-border-radius: 100px 0 0 100px;
    --player-content-margin-right: -15px;
    --player-content-padding-right: 25px;
    --player-content-padding-left: 10px;
    --player-content-margin-left: 0;
    --card-width: 75px;
    --card-height: 112.5px;
    --card-border-radius: 4px;
    --card-back-border-radius: 4px;
    .mobile & {
        --tournament-info-top: -5px;
    }


    .points-desktop-container {
        right: 300px;
        top: 20px;
        position: absolute;
        height: calc(100% - 94px);
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;

        .points-desktop {
            background: white;
            width: 12px;
            border-radius: 10px;
            flex: 1;

            .points-counter {
                background: linear-gradient(90deg, #EB5F5F 0%, #E3E803 100%);
                border-radius: 10px;
                padding: 6px;
                position: absolute;
                bottom: 0;
            }
        }

        .points-desktop-winner {
            position: relative;
            width: 50px;
            z-index: 10;
        }

        .seat-container {
            position: absolute;
            top: 20px;
        }

        .mobile & {
            right: -190px;
            top: 0px;
            scale: 1;

            @media screen and (orientation: landscape) {
                right: -250px;
            }
        }
    }

    .points-mobile-container {
        position: absolute;
        bottom: 15px;
        right: 0;
        left: 0;
        width: 100%;
        height: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        left: 50%;
        transform: translateX(-50%);

        .points-mobile {
            background-color: white;
            height: 18px;
            border-radius: 10px;
            flex: 1;

            .points-counter {
                background: linear-gradient(90deg, #EB5F5F 0%, #E3E803 100%);
                border-radius: 10px;
                padding: 9px;
            }
        }

        .points-mobile-winner {
            position: relative;
            width: 60px;
            height: 60px;
            z-index: 10;
        }
    }

    .card {
        width: var(--soli-card-width, 75px) !important;
        height: var(--soli-card-height, 112.5px) !important;

        .back,
        .card-deck {
            border-radius: 4px;
        }
    }

    .time-container {
        width: var(--time-container-width, 100%);
        position: absolute;
        bottom: var(--time-container-bottom, 0);
        left: var(--time-container-left, 0);
        right: var(--time-container-right, 0);
        transform: var(--time-container-transform);
        margin: 0 auto;
        display: flex;
        align-items: center;
        gap: 10px;

        .time-progress-container {
            border-radius: 10px;
            width: 100%;
            height: var(--time-progress-container-height, 20px);
            display: flex;
            z-index: 10;
            background-color: white;

            .time-counter {
                background: linear-gradient(180deg, #E40000 0%, #461111 100%);
                border-radius: 10px;
                padding: 9px;
            }
        }

        .time-number {
            font-size: var(--time-number-font-size, 20px);
            color: white;
            font-weight: bold;
            z-index: 10;
            min-width: var(--time-number-width, 54px);
        }
    }

    .pile-container {
        width: var(--soli-card-width, 75px);
        height:  var(--soli-card-height, 112.5px);
        position: absolute;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 4px;
        border: 2px solid #FFF;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 4px;
        background-image: url('../../images/add_circle.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 40%;
        z-index: -1;
    }

    .empty-card {
        width: var(--soli-card-width, 75px) !important;
        height: var(--soli-card-height, 112.5px) !important;
        position: absolute;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border: 2px solid #d3d3d3;
        border-radius: 4px;
        z-index: 1;
    }


    .game-card-frame {
        left: 0px;
    }

    .pile-card {
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .MuiInputBase-root {
        width: 180px;
    }

    .player-main {
        flex-direction: row-reverse;
    }

    .player-content {
        text-align: right;
    }

    .player-info {
        transform: var(--player-info-transform, scale(0.8));
        transform-origin: center;
    }

    .card,
    .deck {
        top: 0px;
        img {
            border-radius: 8px;
            .mobile & {
                border-radius: 4px;
            }
        }


        .back,
        .card-deck {
            top: 0px;
            background-color: #193161;
            background-size: 45%;
            border: 2px solid #fff;
            border-radius: 5px;
            background-image: url('../../images/card-up/logo-png.png');
            background-color: var(--background-header-top, #0E345E);
            .mobile & {
                border-width: 1px;
                border-radius: 2px;
                background-size: 55%;
                image-rendering: pixelated;
                image-rendering: -moz-crisp-edges;
                image-rendering: -o-crisp-edges;
                image-rendering: -webkit-optimize-contrast;
            }
        }
    }
    .mobile & {
        .player-content {
            display: none;
        }
    }
}

.background-solitario {
    position: var(--background-solitario-position, absolute);
    top: 0;
    left: 0;
    right: 0;
    bottom: 54px;
    background-color: #076260;
    border-radius: var(--background-solitario-border-radius, 20px);
}

.mobile {
    --background-solitario-position: fixed;
    --time-container-bottom: -102px;
    --time-container-width: 145%;
    --time-container-transform: translateX(-50%);
    --time-progress-container-height: 18px;
    --time-number-font-size: 18px;
    --time-number-width: 40px;
    --player-info-transform: 1;
    --time-container-left: 50%;
  
    #solitario-wrapper {
        transform-origin: top center;
    }
    @media screen and (orientation: landscape) {
        --soli-card-width: 45px;
        --soli-card-height: 67.5px;
        .Solitario #table-round {
            bottom: 70px;
        }
    }
    @media screen and (orientation: portrait) {
        --soli-card-width: 44px;
        --soli-card-height: 65px;
        .Solitario #table-round {
            bottom: 80px;
        }
    }
}

.background-solitario-mobile {
    background: #076260;
    width: var(--background-solitario-mobile-width);
    height: var(--background-solitario-mobile-height);
    position: fixed;
    top: var(--background-solitario-mobile-top);
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    z-index: -1;

    @media screen and (orientation: landscape) {
        --background-solitario-mobile-height: calc(100% * 1.17);
        --background-solitario-mobile-width: 168%;
        --background-solitario-mobile-top: calc(50% - 23px);
    }

    @media screen and (orientation: portrait) {
        --background-solitario-mobile-height: 115%;
        --background-solitario-mobile-width: 165%;
        --background-solitario-mobile-top: 40%;
    }
}
