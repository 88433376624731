$screen-sm: 640px;
$screen-md: 768px;
$screen-lg: 1024px;
$screen-xl: 1280px;

.chip-stack {
  position: absolute;
  z-index: 9999;

  .chip-item {
    width: var(--chip-item-width, 52px);
    height: var(--chip-item-width, 52px);
    min-width: var(--chip-item-width, 52px);
    transform: translate(-50%, var(--chip-item-skew, 17px));
  }

  .pot & {
    .amount-text {
      top: -20px;
      text-align: center;
      width: var(--amount-text-width, 58px);
      left: 0;
      transform: translateX(-50%);
    }
    left: 50%;
  }
}

.player.chip-stack {
  .chip-item {
    transform: none;
  }
  .amount-text {
    left: 50px;
    top: 8px !important;

    .mobile & {
      left: 35px;
    }
  }
}

.pot {
  --chip-item-width: 76px;
  --chip-item-skew: 26px;
}

.rake {
  --amount-text-width: 52px;
  .amount-text {
    left: 0;
    transform: translateX(-50%);
  }
}

.last-action:not(:empty) {
  position: absolute;
  display: flex;
  width: 90px;
  height: 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #0C3332;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #fff;

  .mobile & {
    width: 66.528px;
    height: 11.659px;
    font-size: 9px;
  }
}

.combination:not(:empty) {
  position: absolute;
  display: flex;
  width: 200px;
  height: 30px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  background: #0B62A1;
  box-shadow: 0px 0px 4px 0px #000;
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 700;

  .mobile & {
    width: 144px;
    height: 22px;
    font-size: 12px;
  }
}

.amount-text {
  color: #fff;
  font-weight: bold;
  font-size: var(--amount-text-font-size, 18px);
  white-space: nowrap;
  position: absolute;
}

.card,
.deck {
  position: absolute;
  left: var(--card-left, -1.9375rem);
  top: var(--card-top, -2.75rem);
  width: var(--card-width, 125px);
  height: var(--card-height, 160px);
  border-radius: 8px;
  z-index: 1;
  cursor: default;
  will-change: transform;

  img {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0px 0px 1px rgb(0 0 0 / 20%);
    width: 100%;
    height: 100%;
  }

  .face,
  .back,
  .card-deck {
    height: 100%;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .back {
    position: absolute;
    background-image: url('../images/card.svg');
    width: 100%;
    top: 0;
    left: 0;
    border-radius: 8px;
  }

  .highlight {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 6px #D66C01 !important;
    border-radius: 8px;
    overflow: hidden;

    &.taken {
      box-shadow: 0 0 0 6px #01d613 !important;
    }
  }

  .highlight-many {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 0 5px #D66C01;
    border-radius: 8px;
    overflow: hidden;

    &.taken {
      box-shadow: 0 0 0 6px #01d613 !important;
    }
  }

  .Briscola &,
  .Tresette &,
  .Scopa &,
  .Cirulla &,
  .ItalianPoker &,
  .Scala40 &,
  .Burraco &,
  .Bestia &,
  .Telesina &,
  .Peppa {
    top: 0;
    left: 0;
  }
}

.card .back {
  background-size: 45%;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: -o-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  background-image: none;
  
  .quantico & {
    background-image: url('../images/card-up/logo-png.png');
  }

  .snai & {
    background-image: url('../images/card-up/logo-snai-png.png');
  }

  .sisal & {
    background-image: url('../images/card-up/logo-sisal-png.png');
  }
}

// .Briscola,
.Tresette,
.Peppa,
.AssoPigliaTutto {
  .card {
    height: 202px;

    img {
      border-radius: 8px;
    }
  }
}
.Briscola,
.Tresette,
.Scopa,
.Scopone,
.ItalianPoker,
.Scala40,
.Cirulla,
.Burraco,
.Telesina,
.SetteEMezzo,
.Bestia,
.Peppa,
.Trentuno {
  #qg-cirulla,
  #gg-scopa,
  #gg-scopone,
  #gg-italianPoker,
  #gg-telesina,
  #qg-bestia {
    -webkit-user-drag: none;
  }

  .card,
  .deck {
    img {
      border-radius: 4px;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
    }

    .back,
    .card-deck {
      border: 2px solid #fff;
      background-color: #193161;
      border-radius: 8px;

      background-size: 45%;
      image-rendering: pixelated;
      image-rendering: -moz-crisp-edges;
      image-rendering: -o-crisp-edges;
      image-rendering: -webkit-optimize-contrast;

      .snai & {
        background-image: url('../images/card-up/logo-snai-png.png');
      }

      .quantico,
      .quinel & {
        background-image: url('../images/card-up/logo-png.png');
      }

      .sisal & {
        background-image: url('../images/card-up/logo-sisal-png.png');
      }
    }
  }

  .card,
  .card-deck {
    box-shadow: 0 0 8px rgba($color: #000000, $alpha: 1);
  }
}

.card,
.deck {
  .ItalianPoker,
  .Bestia & {
    height: 144px;
    width: 96px;
  }

  .Scala40 &,
  .Burraco & {
    width: 57.333px;
    height: 86px;
  }
}

.SetteEMezzo {
  .card {
    height: 206.56px;
    left: 0;
    top: 0;

    .back {
      @media screen and (max-width: 1024px) {
        height: 100%;
      }

      .snai & {
        background-image: url('../images/card-up/logo-snai-sette.svg');
      }

      .quantico & {
        background-image: url('../images/card-up/logo-quantico-sette.svg');
      }

      .sisal & {
        background-image: url('../images/card-up/logo-sisal-sette.svg');
      }
    }
  }
}

.player-bg {
  height: 98px;
  background-color: rgba($color: #000000, $alpha: 0.1);
  border-radius: 8px;
  position: absolute;
  width: 550px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #114443;

  .mobile & {
    width: 100%;
    height: 70px;

    @media screen and (orientation: landscape) {
      max-width: 329px;
    }
  }
}

.player-collapse {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #0E2A2C;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  overflow: hidden;
  font-size: 6px;
  display: flex;

  @media screen and (min-width: $screen-xl) {
    font-size: 10px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
}

.player-info {
  display: flex;
  align-items: center;
}

.player-avatar {
  position: relative;
  z-index: 2;
  width: var(--player-avatar-width, 70px);
  height: var(--player-avatar-width, 70px);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 2px solid #B57E11;
  }

  &-main {
    border-radius: 50%;
    position: relative;
    aspect-ratio: 1 / 1 !important;
  }
}

.player-avatar-image {
  img {
    border-radius: 50%;
  }

  &::before {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 1;
  }
}


.player-content {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-family: 'Inter';
  justify-content: center;
  margin-left: var(--player-content-margin-left, -25px);
  margin-right: var(--player-content-margin-right, 0);
  border: 1px solid #9E9E9E;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(7.5px);
  border-radius: var(--player-content-border-radius, 0 100px 100px 0);
  height: var(--player-content-height, 52.5px);
  width: var(--player-content-width, 147px);
  padding-left: var(--player-content-padding-left, 30px);
  padding-right: var(--player-content-padding-right, 10px);
  overflow: hidden;
}

.player-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: var(--player-name-font-size, 15px);
  line-height: 1.1;
  overflow: hidden; 
  + .player-ranking-list {
    margin-top: 2px;
  }
}


.flex-row-reverse {
  --player-content-margin-right: -25px;
  --player-content-margin-left: -25px;
  --player-content-border-radius: 100px 0 0 100px;
  --player-content-padding-right: 30px;

  .player-content {
    align-items: flex-end;
  }

  .mobile & {
    --player-content-margin-left: 0;
    --player-content-margin-right: -15px;
    --player-content-padding-right: 20px;
  }
}

.player-time {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: bold;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  background-color: rgba($color: #000000, $alpha: 0.4);

  .mobile & {
    font-size: 10px;
  }
}

.player-status {
  font-size: 20px;
  font-weight: 900;
  -webkit-text-stroke: 1.5px #000000;

  .mobile & {
    font-size: 15px;
    -webkit-text-stroke-width: 1px;
  }
}

.player-content {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}

.player-name {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.player-dealer {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  right: -13px;
  width: 35px;

  .mobile .flex-row-reverse & {
    left: 0;
    right: auto
  }

  .mobile & {
    right: 0;
    left: auto;
  }

  @media screen and (min-width: $screen-xl) {
    top: 66%;
    width: 45px;
  }
}

.player-counter {
  position: absolute;
  border-radius: 50%;
  background-color: #092020;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player-avatar {
  z-index: 2;
}

.player-counter {
  z-index: 3;
}

.player-balance {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: var(--player-balance-font-size, 12px);
  color: #fff;
  margin-top: 2px;
}

.number-deck {
  color: #fff;
  font-family: "Raleway", sans-serif !important;
  font-weight: bold;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 25px;
  min-height: 25px;
  padding: 0 5px;
  z-index: 9999;
  bottom: 20%;
  left: 0;
  right: 0;
  font-size: 30px;
}

.deck {
  position: relative;
  width: 75px;
  height: 126.5px;

  &:not(.hidden) {
    display: inline-block;

    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      border-radius: 4px;
      background-color: rgba(9, 32, 32, 0.65);
    }

    &::after {
      content: "CARTE MAZZO";
      font-size: var(--deck-after-font-size, 12px);
      display: flex;
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      color: #fff;
      z-index: 999;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-family: "Raleway", sans-serif !important;
      font-weight: bold;
    }
  }
}

.pile {
  position: absolute;
  top: 0;
  left: 0;
}

.player-main {
  display: flex;
  align-items: center;
}

.mobile {
  --player-avatar-width: 50px;
  --player-name-font-size: 10px;
  --player-content-margin-left: -15px;
  --player-time-font-size: 10px;
  --player-content-width: 108px;
  --player-content-padding-left: 20px;
  --player-content-height: 37.5px;
  --player-ranking-list-margin-left: -2px;
  --player-ranking-list-img-max-width: 12px;
  --player-status-font-size: 15px;
  --player-status-stroke: 1px;
  --player-balance-font-size: 8px;
  --player-passo-font-size: 10px;
  --player-passo-stroke: 0.5px;
  --chip-item-width: 37px;
  --chip-item-skew: 12px;
  --amount-text-left: 0;
  --last-action-width: 66.528px;
  --last-action-height: 11.659px;
  --last-action-font-size: 9px;
  --combination-width: 144px;
  --combination-height: 22px;
  --combination-font-size: 12px;
  --amount-text-font-size: 12px;
  --card-back-border-width: 1px;
  --card-back-border-radius: 4px;
  --hand-win-width: 100px;

  .pot {
    --chip-item-width: 58px;
    --chip-item-skew: 19px;
  }

  .rake {
    --amount-text-width: 37px;
  }
  
  .result-you,
  .result-me {
    background: rgba(11, 14, 17, 0.2);
    border-radius: 8px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    color: #fff;
    font-size: 11px;
    padding-left: 10px;
    padding-right: 10px;
    position: fixed;
    left: 0;
    right: 0;
  }

  .result-you {
    top: 2px;
  }

  .result-me {
    bottom: 2px;
  }

  @media (orientation: landscape) {

    .result-you,
    .result-me {
      bottom: 8px;
      top: auto;
      transform: translateX(0);
      width: calc(50% - 60px);
      min-width: 300px;
      max-width: 360px;
    }

    .result-me {
      right: 0;
      left: auto;
    }

    .result-you {
      left: 0;
      right: auto;
    }
  }
}

.deck.deck-vertical {
  .number-deck {
    rotate: 180deg;
    writing-mode: vertical-rl;
    left: 22%;
    top: 0;
    width: 100%;
    height: 100%;

    .Scopa & {
      left: 12px;
    }
    .Cirulla & {
      left: 12px;
    }
  }

  &::after {
    rotate: 180deg;
    writing-mode: vertical-rl;
    left: -15px;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 30px;

    .Scopa & {
      left: -12px;
    }
    .Cirulla & {
      left: -12px;
    }
  }
}

.game-hand {
  position: absolute;
}

.hand-highlight {
  filter: drop-shadow(0px 4px 66px #FFC857);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
}

.game-hand {
  position: absolute;
  top: 0;
  left: 0;
}

.game-hand .win {
  position: absolute;
  width: var(--hand-win-width, 137px);
  height: 91px;
  flex-shrink: 0;
  background: url('../../../public/images/common/win.png');
  display: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 9999 !important;
}

.game-hand .win.active {
  display: block;
}

.list-meld {
  background-color: #076260;
  display: block;
  position: absolute;
  z-index: -1;

  .mobile & {
    @media screen and (orientation: landscape) {
      height: auto;
    }

    @media screen and (orientation: portrait) {
      width: 100%;
      left: 0;
    }
  }
}

.list-meld-us {
  border-radius: 8px 0 0 8px;

  .mobile & {
    @media screen and (orientation: landscape) {
      transform: translate(0, 0);
      border-radius: 8px 0 0 8px;
    }

    @media screen and (orientation: portrait) {
      height: auto;
      border-radius: 0 0 8px 8px;
    }
  }
}

.list-meld-them {
  border-radius: 0 8px 8px 0;

  .mobile & {
    @media screen and (orientation: landscape) {
      transform: translate(0, 0);
      left: auto;
      border-radius: 0 8px 8px 0;
    }

    @media screen and (orientation: portrait) {
      border-radius: 8px 8px 0;
    }
  }
}

.player-sort-suit,
.player-sort-value {
  display: flex;
  position: absolute;
  visibility: hidden;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  span {
    transition: top 0.5s ease;
    width: 25px;

    .mobile & {
      width: 21.23px;
    }

    &:nth-child(1) {
      position: absolute;
      z-index: 1;
      left: -14px;

      .mobile & {
        left: -11.89px;
      }
    }

    &:nth-child(2) {
      z-index: 2;
    }

    &:nth-child(3) {
      position: absolute;
      right: -14px;
      z-index: 3;

      .mobile & {
        right: -11.89px;
      }
    }
  }
}

.player-sort-suit,
.active.player-sort-value {
  span {
    &:nth-child(1) {
      top: -6px;

      .mobile & {
        top: -5.09px
      }
    }

    &:nth-child(3) {
      top: 6px;

      .mobile & {
        top: 5.09px
      }
    }
  }
}

.active.player-sort-suit,
.player-sort-value {
  span {
    &:nth-child(1) {
      top: 6px;

      .mobile & {
        top: 5.09px
      }
    }

    &:nth-child(3) {
      top: -6px;

      .mobile & {
        top: -5.09px
      }
    }
  }
}

.active.player-sort-value,
.active.player-sort-suit {
  display: flex;
  visibility: visible;
  z-index: 2;
}

.player-sort-box {
  height: 50px;
  overflow: hidden;
  width: 53px;
  justify-content: center;
  align-items: center;

  .mobile & {
    width: 45px;
    height: 41.6px;
  }
}