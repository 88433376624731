.btn,
.link-hover,
.btn-action {
  position: relative;
  transition: all .35s;
  overflow: hidden;
  white-space: nowrap;

  * {
    z-index: 1;
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 0;
    bottom: 0;
    transition: all .35s;
  }

  &:hover,
  &:focus,
  &.active {
    &::before {
      width: 100%;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;
  font-size: 12px;
  font-weight: 700;
  @extend .font-raleway;
  overflow: hidden;
  text-shadow: none;
}

.btn-icon {
  +.btn-text {
    margin-left: 10px;
  }
}

.btn-noti {
  width: 11.5px;
  height: 11.5px;
  position: absolute;
  top: 4px;
  right: 0;
  background-color: #CD1B1B;
  border: 2px solid #fff;
  border-radius: 50%;

  &::after {
    content: '';
    position: absolute;
  }
}

@mixin btn-size($min-width, $height, $padding, $border-radius) {
  min-width: $min-width;
  height: $height;
  padding: $padding;
  border-radius: $border-radius;
}

@mixin btn-color($color, $background, $border, $box-shadow, $color-hover, $background-hover, $border-hover, $box-shadow-hover) {
  color: $color;
  background-color: $background;
  border: $border;
  box-shadow: $box-shadow;

  &::before {
    background-color: $background-hover;
  }

  .btn-icon path {
    fill: $color;
  }

  &:hover,
  &:focus,
  &.active {
    color: $color-hover;
    box-shadow: $box-shadow-hover;
    border: $border-hover;
    background-color: $background-hover;

    .btn-icon path {
      fill: $color-hover;
    }
  }
}

.btn-size {
  @include btn-size(auto, 44px, 0 10px, 8px);
  font-size: 16px;
  text-transform: uppercase;

  .mobile & {
    @include btn-size(auto, 36px, 0 16px, 8px);
    font-size: 12px;
  }
}


%btn-fab {
  @include btn-size(54px, 54px, 0, 50%);
}

%btn-round-small {
  @include btn-size(auto, 36px, 0 16px, 8px);
}

.btn-primary {
  @include btn-size(auto, 36px, 0 16px, 999px);
  @include btn-color(#24DEDB, #144B46, none, none, #123335, #24DEDB, #24DEDB, 0px 3px 12px rgba(36, 222, 219, 0.24));
}

.btn-primary-custom {
  @include btn-size(auto, 36px, 0 16px, 999px);
  @include btn-color(#0E345E, #fff, none, none, #123335, #24DEDB, #24DEDB, 0px 3px 12px rgba(36, 222, 219, 0.24));
}

.btn-secondary {
  @extend %btn-round-small;
  @include btn-color(#175EBE, #fff, 1px solid #175EBE, none, #fff, #175EBE, 1px solid #175EBE, none);
}

.btn-primary-alt {
  @include btn-size(auto, 36px, 0 16px, 4px);
  @include btn-color(#fff, #175EBE, none, 0px 3px 6px rgba(23, 94, 190, 0.24), #fff, #226DD1, none, 0px 3px 6px rgba(23, 94, 190, 0.24));
}

.btn-secondary-neutral-light {
  @extend %btn-round-small;
  @include btn-color(#9EA8B4, #fff, 1px solid#9EA8B4, none, #9EA8B4, #F0F1F3, 1px solid #F0F1F3, none);
}

.btn-secondary-neutral {
  @extend %btn-round-small;
  @include btn-color(#CD1B1B, #fff, none, none, #fff, #CD1B1B, none, none);
}

//btn in game
.btn-warning {
  @extend .btn-size;
  @include btn-color(#fff, #BE2117, none, none, #fff, #A61208, none, none);
}

.btn-green {
  @extend .btn-size;
  @include btn-color(#fff, #1CBC3F, none, none, #fff, #0B9028, none, none);
}

.btn-success,
.btn-blue {
  @extend .btn-size;
  @include btn-color(#fff, #175EBE, none, none, #fff, #044AA8, #175EBE, #175EBE);
}

.btn-yellow {
  @extend .btn-size;
  @include btn-color(#fff, #BBBE17, none, none, #fff, #929507, none, none);
}

.btn-fab-blue {
  @extend %btn-fab;
  @include btn-color(#fff, #175EBE, none, 0px 3px 12px rgba(23, 94, 190, 0.38), #fff, #175EBE, none, none);
}

.btn-fab-outline {
  border: none;
}

.btn-mini-fab {
  @include btn-size(48px, 48px, 0, 50%);
  width: 48px;
  @include btn-color(#fff, #C2CDDB, none, none, #fff, #24DEDB, none, 0px 3px 12px rgba(36, 222, 219, 0.46));

  @media screen and (min-width: 768px) {

    @include btn-size(36px, 36px, 0, 50%);
    width: 36px;
  }
}

.btn-italian {
  max-width: 80px;
}