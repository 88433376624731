@tailwind base;
@tailwind components;
@tailwind utilities;

.hidden {
  display: none !important;
}

.flex-1 {
  flex: 1;
}

ul li,
ol li {
  list-style: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

.font-hind {
  font-family: 'Hind', sans-serif;
}

.font-inter {
  font-family: 'Inter', sans-serif !important;
}

body {
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  line-height: 1.285714285714286;
  color: #0b0e11;
}

h1,
.h1 {
  font-size: 40px;
  line-height: 2;
}

h2,
.h2 {
  font-size: 30px;
  line-height: 2;
}

h3,
.h3 {
  font-size: 24px;
  line-height: 34px;
}

h4,
.h4 {
  font-size: 20px;
  line-height: 28px;
}

h5,
.h5 {
  font-size: 18px;
  line-height: 20px;
}

.body-1,
h6,
.h6 {
  font-size: 14px;
  line-height: 18px;
}

.sub-title {
  font-size: 18px;
  line-height: 22px;
}

.body-2 {
  font-size: 14px;
  line-height: 18px;
}

.detail,
.link,
.text-sm {
  font-size: 12px;
  line-height: 16px;
}

.empty {
  width: 100%;
  height: 100%;
  border-color: white;
  border-width: 1px;
  background-image: url('../images/add_circle.svg');
  background-repeat: no-repeat;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  background-size: 35%;
  background-position: center;
}

@media all and (min-width: 1024px) {
  .empty {
    border-width: 2px;
    border-radius: 8px;
  }
}

.Scala40 .win-box,
.Burraco .win-box,
.Briscola .win-box,
.Scopa .win-box,
.Tresette .win-box,
.Peppa .win-box,
.AssoPigliaTutto .win-box {
  display: none !important;
}

.announce-text {
  font-family: 'Raleway';
  position: absolute;
  z-index: 500;
  top: 50%;
  left: 50%;
  font-size: 80px;
  color: white;
  background: -webkit-linear-gradient(90deg, rgba(255, 0, 0, 1) 0%, rgba(0, 181, 255, 1) 100%);
  translate: -50% -50%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px;
  white-space: nowrap;
  transform: scale(0);
  transition: all 1s;
}

.announce-text.show {
  transform: scale(1);
}
